import React, {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import hexColorIsLight from '../../assets/functions/hexColorIsLight.js'
import formatMoney from '../../assets/functions/formatMoney.js'
import MenuIcon from '../shared/icons/menuIcon.jsx'

import Context from './context.jsx'

export default function Header() {
  const {authenticated, brand, selectedState, logout, consumer, history} =
    useContext(Context)

  const [navOpen, setNavOpen] = useState(false)
  const [balanceAmount, setBalanceAmount] = useState('')

  useEffect(() => {
    if (consumer && consumer.pending_consumer_balance)
      setBalanceAmount(
        formatMoney(consumer.pending_consumer_balance.balance_cents),
      )
    else setBalanceAmount(formatMoney(0))
  }, [consumer])

  useEffect(() => setNavOpen(false), [history.location.pathname])

  let headerTextColor = 'text-light'
  if (brand)
    headerTextColor = hexColorIsLight(brand.background_accent_color)
      ? 'text-dark'
      : 'text-light'

  let balanceTextColor = 'text-primary'
  if (brand && brand.primary === brand.background_accent_color)
    balanceTextColor = hexColorIsLight(brand.background_accent_color)
      ? 'text-dark'
      : 'text-light'

  return (
    <>
      {navOpen ? (
        <div
          style={{height: `calc(100% - 4rem`}}
          className='absolute z-30 top-16 right-0 mx-auto w-5/6 bg-white text-dark shadow-lg'>
          {consumer ? (
            <div className='p-4 text-center divide-y font-bold uppercase'>
              {authenticated ? (
                <Link
                  to='/balance'
                  className={`block pb-4 text-xl ${balanceTextColor}`}>
                  {balanceAmount}
                </Link>
              ) : (
                <></>
              )}

              {authenticated ? (
                <Link to='/account' className='block py-4'>
                  {consumer.phone && consumer.phone_verified_at ? (
                    <></>
                  ) : (
                    <ExclamationShieldIcon extraClasses='mr-1' />
                  )}
                  <span className='align-middle'>Account</span>
                </Link>
              ) : (
                <></>
              )}

              {authenticated ? (
                <button
                  onClick={logout}
                  className='w-full md:w-auto pt-4 font-bold uppercase'>
                  Log Out
                </button>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <Link
              to='/login'
              className='block py-4 text-center font-bold uppercase'>
              Log In
            </Link>
          )}
        </div>
      ) : (
        <></>
      )}

      <header
        className={`block fixed w-full h-16 z-10 px-4 bg-background-accent-color shadow font-primary ${headerTextColor}`}>
        <nav className='container h-full flex justify-between items-center mx-auto uppercase'>
          <div className='w-1/3 text-center mr-2'>
            <Link to='/'>
              {brand ? (
                <img
                  className='max-h-12'
                  src={brand.logo_image_url}
                  alt='Logo'
                />
              ) : (
                <></>
              )}
            </Link>
          </div>

          {selectedState ? (
            <div className='w-1/3 text-center'>
              <Link to='/'>Offers in </Link>
              <span className='font-bold'>
                <Link to='/location'>{selectedState.name}</Link>
              </span>
            </div>
          ) : (
            <></>
          )}

          <div className='w-1/3 text-right'>
            <div className='hidden sm:block'>
              {authenticated && consumer ? (
                <Link
                  to='/balance'
                  className={`mr-4 align-middle text-lg font-bold ${balanceTextColor}`}>
                  {balanceAmount}
                </Link>
              ) : (
                <></>
              )}

              {authenticated ? (
                <Link to='/account'>
                  {consumer.phone && consumer.phone_verified_at ? (
                    <></>
                  ) : (
                    <ExclamationShieldIcon extraClasses='mr-1' />
                  )}
                  <span className='mr-4 align-middle text-sm font-bold uppercase'>
                    Account
                  </span>
                </Link>
              ) : (
                <></>
              )}

              {authenticated ? (
                <button
                  onClick={logout}
                  className='align-middle text-sm font-bold uppercase'>
                  Log Out
                </button>
              ) : (
                <Link
                  to='/login'
                  className='align-middle text-sm font-bold uppercase'>
                  Log In
                </Link>
              )}
            </div>

            <button onClick={() => setNavOpen(!navOpen)} className='md:hidden'>
              <MenuIcon size='8' extraClasses={'ml-auto'} />
            </button>
          </div>
        </nav>
      </header>
    </>
  )
}

function ExclamationShieldIcon({extraClasses}) {
  return (
    <svg
      className={`inline-block h-5 w-5 ${extraClasses}`}
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        d='M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z'
        clipRule='evenodd'
      />
    </svg>
  )
}
